.fa,
.fas,
.far,
.fal,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}
/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */

.fa-2x {
  font-size: 2em;
}
.fa-3x {
  font-size: 3em;
}
.fa-envelope:before {
  content: "\f0e0";
}
.fa-phone-square-alt:before {
  content: "\f87b";
}
.fa-angle-right:before {
  content: "\f105";
}
.fa-arrow-right:before {
  content: "\f061";
}
.fa-briefcase-medical:before {
  content: "\f469";
}
.fa-briefcase:before {
  content: "\f0b1";
}
.fa-building:before {
  content: "\f1ad";
}
.fa-bezier-curve:before {
  content: "\f55b";
}
.fa-cog:before {
  content: "\f013";
}
.fa-check:before {
  content: "\f00c";
}
.fa-chart-pie:before {
  content: "\f200";
}
.fa-star:before {
  content: "\f005";
}
.fa-calendar:before{
  content: "\f073";
}
.fa-map-marker:before{
  content: "\f041";
}
.fa-comment:before {
  content: "\f075";
}
.fa-clock:before {
  content: "\f017";
}
.fa-dot-circle:before {
  content: "\f192'";
}
.fa-file-invoice-dollar::before {
  content: "\f571";
}
.fa-file-invoice::before {
  content: "\f570";
}
.fa-certificate::before {
  content: "\f0a3";
}
.fa-file-pdf:before {
  content: "\f1c1";
}
.fa-file-word:before {
  content: "\f1c2";
}
.fa-fingerprint:before {
  content: "\f577";
}
.fa-folder-open:before {
  content: "\f07c";
}
.fa-facebook-f:before {
  content: "\f39e";
}
.fa-facebook:before {
  content: "\f09a";
}
.fa-github:before {
  content: "\f09b";
}
.fa-google:before {
  content: "\f1a0";
}
.fa-history:before {
  content: "\f1da";
}
.fa-instagram:before {
  content: "\f16d";
}
.fa-layer-group:before {
  content: "\f5fd";
}
.fa-linkedin-in:before {
  content: "\f0e1";
}
.fa-map-marker-alt:before {
  content: "\f3c5";
}
.fa-paper-plane:before {
  content: "\f1d8";
}
.fa-phone:before {
  content: "\f095";
}
.fa-phone-alt:before {
  content: "\f879";
}
.fa-plus:before {
  content: "\f067";
}
.fa-truck:before {
  content: "\f0d1";
}
.fa-twitter:before {
  content: "\f099";
}
.fa-user:before {
  content: "\f007";
}
.fa-user-friends:before {
  content: "\f500";
}
.fa-server:before {
  content: "\f233";
}
.fa-brands:before {
  content: "\f370";
}
.fa-database:before {
  content: "\f1c0";
}
.fa-wallet:before {
  content: "\f555";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  src: url("./webfonts/fa-brands-400.eot");
  src: url("./webfonts/fa-brands-400.eot?#iefix") format("embedded-opentype"),
    url("./webfonts/fa-brands-400.woff2") format("woff2"),
    url("./webfonts/fa-brands-400.woff") format("woff"),
    url("./webfonts/fa-brands-400.ttf") format("truetype");
}

.fab {
  font-family: "Font Awesome 5 Brands";
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 400;
  src: url("./webfonts/fa-regular-400.eot");
  src: url("./webfonts/fa-regular-400.eot?#iefix") format("embedded-opentype"),
    url("./webfonts/fa-regular-400.woff2") format("woff2"),
    url("./webfonts/fa-regular-400.woff") format("woff"),
    url("./webfonts/fa-regular-400.ttf") format("truetype"),
    url("./webfonts/fa-regular-400.svg#fontawesome") format("svg");
}

.far {
  font-family: "Font Awesome 5 Free";
  font-weight: 400;
}
@font-face {
  font-family: "Font Awesome 5 Free";
  font-style: normal;
  font-weight: 900;
  src: url("./webfonts/fa-solid-900.eot");
  src: url("./webfonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
    url("./webfonts/fa-solid-900.woff2") format("woff2"),
    url("./webfonts/fa-solid-900.woff") format("woff"),
    url("./webfonts/fa-solid-900.ttf") format("truetype");
}

.fa,
.fas {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
