@media (max-width: 1550px) {
  .container.custom_container {
    max-width: 1170px;
  }

  .company_widget img {
    max-width: 100%;
  }

  .service_carousel {
    padding-left: 70px;
  }

  .menu>.nav-item:nth-last-child(-n + 2).submenu .dropdown-menu {
    left: auto;
    right: 0;
  }

  .new_startup_img img {
    max-width: 100%;
  }
}

@media (max-width: 1450px) {
  .app_feature_info .col-lg-5 {
    text-align: center;
  }

  .scroll-wrap {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

@media (max-width: 1199px) {
  .container.custom_container {
    max-width: 960px;
  }

  .f_width_12 {
    width: 15%;
  }

  .f_width_20 {
    width: 25%;
  }

  .f_width_24,
  .f_width_12,
  .f_width_20 {
    margin-bottom: 35px;
  }

  .tab-content .tab_img {
    padding-left: 0;
  }

  .service_carousel .owl-stage {
    left: 0;
  }

  .blog_content {
    padding: 25px 15px 42px 20px;
  }

  .menu>.nav-item+.nav-item {
    margin-left: 15px;
  }

  .faq_area .pr_50 {
    padding-right: 15px;
  }

  .portfolio_details_info.pl_100 {
    padding-left: 0;
    padding-right: 0;
  }

  .app_feature_info+.app_feature_info {
    margin-top: 175px;
  }

  .navbar .pl_100 {
    padding-left: 48px;
  }

  .error_content_two {
    max-width: 960px;
    margin: 0 auto;
  }

  .error_content_two img {
    max-width: 60%;
  }

  .error_content_two h2 {
    font-size: 32px;
    line-height: 44px;
    margin-top: 35px;
  }

  .menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu {
    max-width: 960px;
  }

  .f_widget.pl_70,
  .f_widget.pl_40 {
    padding-left: 0;
  }

  .menu_one .container-fluid {
    padding-left: 35px;
    padding-right: 72px;
  }

  .last-3 {
    width: 0;
  }
}

@media (max-width: 991px) {
  .header_top {
    display: none !important;
  }

  .sticky_logo {
    margin: 0;
  }

  .navbar-brand {
    margin-top: 0;
  }

  .navbar_logo {
    width: 60px;
  }

  .navbar_fixed .header_area .menu_one {
    padding: 5px 0px;
  }

  .breadcrumb_area .breadcrumb_shap {
    right: 10%;
  }

  .breadcrumb_area.banner_top .container {
    height: 370px;
  }

  .breadcrumb_area.banner_top .contact_container {
    height: 320px;
  }

  .breadcrumb_area.banner_top .container_with_button {
    height: 320px;
  }

  .banner_top {
    padding-top: 80px;
  }

  .banner_top br {
    display: none;
  }

  .footer_top .f_widget {
    padding-left: 0px;
    margin-bottom: 40px;
  }

  .footer_top {
    padding-bottom: 60px;
  }

  .footer_bottom,
  .f_social_icon_two a {
    font-size: 13px;
  }

  .container.custom_container {
    max-width: 100%;
    padding: 0px 15px;
  }

  .service_carousel:before {
    display: none;
  }

  .software_featured_area_two .pl-0 {
    padding-left: 15px !important;
  }

  .app_testimonial_area .nav_container .owl-prev {
    left: 0;
  }

  .app_testimonial_area .nav_container .owl-next {
    right: 0;
  }

  .app_testimonial_area .nav_container .owl-prev,
  .app_testimonial_area .nav_container .owl-next {
    opacity: 0;
  }

  .app_testimonial_area .nav_container:hover .owl-next,
  .app_testimonial_area .nav_container:hover .owl-prev {
    opacity: 1;
  }

  .faq_tab {
    margin-bottom: 60px;
  }

  .faq_content h3.mt_100 {
    margin-top: 50px;
  }

  .portfolio_details_info {
    padding-left: 0px;
    padding-right: 0px;
  }

  .portfolio_details_info .portfolio_category {
    margin-top: 40px;
  }

  .portfolio_details_info .portfolio_pagination {
    margin-top: 70px;
    margin-bottom: 50px;
  }

  .portfolio_details_area .portfolio_details_gallery.gallery_two {
    margin-bottom: 20px;
  }

  .portfolio_details_info .portfolio_pagination_two {
    margin-bottom: 0px;
  }

  .portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description {
    padding-left: 30px;
    padding-bottom: 28px;
  }

  .portfolio_gallery .portfolio_item .portfolio_img .hover_content .img_popup {
    right: 30px;
    top: 30px;
  }

  .sec_title p br {
    display: none;
  }

  .menu>.nav-item+.nav-item {
    margin-left: 0px;
  }

  .menu>.nav-item {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .menu {
    padding: 10px 30px;
    background: #fff;
    margin-top: 0px;
  }

  .menu_one .navbar-collapse {
    margin-left: -20px;
    margin-right: -20px;
  }

  .navbar-collapse,
  .menu_four .navbar-collapse {
    max-height: 400px;
    overflow-y: scroll;
    margin-left: 0px;
    margin-right: 0px;
    background: #fff;
  }

  .menu>.nav-item {
    margin-right: 0;
  }

  .header_area .navbar-brand,
  .menu_toggle {
    transition: all 0.2s linear;
  }

  .navbar .pl_100 {
    padding-left: 20px;
    padding-right: 15px;
  }

  .header_area.navbar_fixed .navbar-brand,
  .navbar_fixed .menu_toggle {
    margin-bottom: 0px;
  }

  .menu>.nav-item>.nav-link {
    display: inline-block;
  }

  .menu>.nav-item.submenu .dropdown-menu .nav-item>.dropdown-menu {
    box-shadow: none;
  }

  .menu>.nav-item.submenu.mega_menu .dropdown-menu .nav-item>.dropdown-menu {
    display: block;
  }

  .menu>.nav-item.submenu .dropdown-menu .nav-item {
    padding: 0px 25px;
  }

  .menu>.nav-item.submenu .dropdown-menu {
    box-shadow: none;
    padding: 0px;
    margin: 0px;
  }

  .menu>.nav-item.submenu.mega_menu .mega_menu_inner {
    box-shadow: none;
    position: static;
  }

  .menu>.nav-item.submenu .dropdown-menu .nav-item.submenu .dropdown-menu .nav-item {
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu>.nav-item.submenu .dropdown-menu .nav-item.submenu .dropdown-menu .nav-item:first-child {
    padding-top: 0;
  }

  .menu>.nav-item.submenu .dropdown-menu .nav-item.submenu .dropdown-menu .nav-item:last-child {
    padding-bottom: 0;
  }

  .menu>.nav-item.submenu .dropdown-menu .nav-item.submenu .dropdown-menu .nav-item .nav-link:before {
    content: "\e649";
    display: inline-block;
    font-family: "themify";
    margin-right: 8px;
    width: auto;
    font-size: 9px;
    float: none;
  }

  .menu>.nav-item.submenu.mega_menu>.dropdown-menu.show {
    margin-top: 10px;
    box-shadow: none;
    min-height: 350px;
  }

  .menu>.nav-item.submenu.mega_menu>.dropdown-menu>.nav-item+.nav-item {
    padding-top: 0px;
  }

  .menu>.nav-item.submenu.mega_menu>.dropdown-menu>.nav-item .dropdown-menu,
  .menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item .dropdown-menu {
    padding-left: 15px;
    opacity: 1;
    visibility: visible;
    min-height: max-content;
  }

  .menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item {
    padding: 0px 25px;
  }

  .menu>.nav-item.submenu .dropdown-menu .nav-item:first-child,
  .menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item:first-child {
    padding-top: 12px;
  }

  .menu>.nav-item.submenu .dropdown-menu .nav-item:last-child,
  .menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item:last-child {
    padding-bottom: 0px;
  }

  .menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item>.dropdown-menu>.nav-item:last-child {
    padding-bottom: 20px;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two>.dropdown-menu {
    display: flex;
    flex-wrap: wrap;
  }

  .menu>.nav-item.submenu.mega_menu>.dropdown-menu>.nav-item {
    width: 100%;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two>.dropdown-menu .nav-item {
    width: calc(100% / 3);
    padding: 15px 20px;
  }

  .header_area a.menu_cus {
    margin-left: 30px !important;
  }

  .header_area .menu_four .menu_custfive {
    border-color: #563bd1;
    color: #563bd1;
    display: inline-block;
  }

  .header_area .container {
    padding-left: 20px;
    padding-right: 20px;
  }

  .menu_one,
  .header_area.navbar_fixed .menu_one {
    padding: 5px 0px;
  }

  .w_menu .nav-item .nav-link {
    color: #051441;
  }

  .w_menu .nav-item:hover .nav-link,
  .w_menu .nav-item.active .nav-link {
    color: #00aff0;
  }

  .menu_four .menu>.nav-item .nav-link {
    color: #051441;
  }

  .menu_four>.menu>.nav-item:hover .nav-link,
  .menu_four .menu>.nav-item.active .nav-link {
    color: #2c6deb;
  }

  .menu_four>.menu>.nav-item:hover>.nav-link:before,
  .menu_four .menu>.nav-item.active>.nav-link:before {
    background: #2c6deb;
  }

  .app_testimonial_area {
    padding-top: 50px;
    padding-bottom: 30px;
  }

  .footer_bottom .f_menu li+li {
    margin-left: 7px;
  }

  .error_content_two p {
    font-size: 15px;
    line-height: 25px;
  }

  .error_content_two img {
    max-width: 93%;
  }

  .error_content_two .search {
    margin: 30px 0px;
  }

  .alter_nav {
    position: absolute;
    right: 63px;
    top: -4px;
  }

  .studies_item .text {
    padding-left: 20px;
    padding-right: 20px;
  }

  .studies_item .text h4 {
    font-size: 18px;
  }

  .seo_sec_title h2 br {
    display: none;
  }

  .new_footer_top .pl_70 {
    padding-left: 0;
  }

  .new_footer_top .f_widget {
    margin-bottom: 50px;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner>.dropdown-menu>.nav-item .dropdown-menu .nav-item {
    padding-left: 10px;
    padding-right: 10px;
  }

  .menu_pos .w_menu .nav-item:hover .nav-link,
  .w_menu .nav-item.active .nav-link {
    color: #5e53fb;
  }

  .navbar_fixed .menu_pos .container .menu_toggle .hamburger span,
  .navbar_fixed .menu_pos .container .menu_toggle .hamburger-cross span {
    background: #051441;
  }

  .header_area .container {
    position: relative;
  }

  .instagram_widget ul {
    max-width: 290px;
  }

  .menu_one .container-fluid {
    padding-left: 35px;
    padding-right: 35px;
  }

  .navbar_fixed .header_area .menu_eight {
    padding: 15px 0px;
  }

  .human_container {
    padding-bottom: 0;
  }

  .last-3 {
    padding-bottom: 10px;
  }

  .nav-item:hover>ul {
    display: block;
  }

  .webDev_showcase {
    padding-bottom: 0;
  }

  .webDev_showcase_container {
    margin: 40px auto;
  }

  .img_contain {
    height: fit-content;
    width: fit-content;
    margin: 0 auto;
    max-width: fit-content;
  }

  .xamarin_img img {
    width: 100%;
    height: 100%;
  }

  .ai_img_outer {
    margin: auto;
  }

  .seo_industry_card .seo_industry_item {
    min-height: 185px;
  }

  .service_title h2 {
    font-size: 30px !important;
  }
}

@media (max-width: 768px) {
  .sec_title br {
    display: none;
  }

  .footer_bottom p {
    text-align: center;
    margin-bottom: 12px !important;
  }

  .footer_bottom .f_social_icon_two {
    text-align: right !important;
    margin-bottom: 12px;
  }

  .footer_bottom .f_menu {
    text-align: center !important;
  }

  .home_bubble {
    display: none;
  }

  .banner_top h2 {
    font-size: 32px;
  }

  .breadcrumb_area .breadcrumb {
    padding: 10px !important;
    font-size: 12px;
  }

  .breadcrumb_area .breadcrumb_content .breadcrumb_content_description {
    width: 100%;
  }

  .breadcrumb_area .breadcrumb_content .breadcrumb_content_title {
    font-size: 25px;
  }

  .breadcrumb_area .breadcrumb_content .breadcrumb_content_heading {
    font-size: 14px;
  }

  .banner_top .breadcrumb_content p {
    width: 100%;
  }

  .seo_banner_content h2 {
    line-height: 48px;
  }

  .seo_banner_content p {
    font-size: 13px;
  }

  .home_slider .home_slider_item .seo_banner_content p {
    max-width: 100% !important;
  }

  .l_height60 {
    line-height: 45px;
  }

  .f_width_12,
  .f_width_20 {
    width: 100%;
  }

  .service_carousel {
    padding-left: 0;
  }

  .mb_90 {
    margin-bottom: 50px;
  }

  .agency_testimonial_area {
    padding-top: 70px;
  }

  .sec_pad {
    padding: 15px 0px;
  }

  .sec_title.mb_70 {
    margin-bottom: 50px;
  }

  .f_widget .mb_40 {
    margin-bottom: 16px;
  }

  .company_widget .mt_30 {
    margin-top: 20px;
  }

  .breadcrumb_content p br {
    display: none;
  }

  .related_services {
    margin-top: 20px;
    margin-bottom: 50px !important;
    padding: 40px 0;
  }

  .portfolio_filter {
    flex-direction: column;
    text-align: center;
    width: 50%;
    margin: 10px auto;
  }

  .work_portfolio_item {
    margin-left: 35px;
  }

  .processcarditem {
    padding: 0 80px;
  }

  .aisol_card {
    padding: 0 80px;
  }

  .aisolution_item {
    min-height: 215px;
  }

  .seo_title_r {
    margin-top: 30px;
  }

  .service_title h2 {
    font-size: 29px !important;
    margin-top: 20px;
  }

  .service_title h2::before {
    font-size: 48px !important;
  }

  .service_title p {
    width: 100% !important;
  }

  h2.overview-title {
    font-size: 25px !important;
    margin-bottom: 15px !important;
  }

  .wwd-service-section.left-section .service-content,
  .testimonial_title {
    padding: 15px 0 0 0 !important;
  }

  .wwd-service-section {
    margin-bottom: 30px !important;
  }

  .testimonial_title .usecase_main__title {
    margin-bottom: 5px;
  }

  .seo_sec_title .usecase_main__title,
  .get-a-quote .content-box .footer-title {
    font-size: 25px !important;
  }

  .get-a-quote .content-box {
    padding: 30px 10px !important;
  }

  .portfolio_fullwidth_area {
    padding-top: 30px !important;
  }

  .work-process .process-card-two.py-5 {
    padding: 15px 30px !important;
  }

  .work-process .process-card-two .process-icon {
    margin-bottom: 15px !important;
  }

  .main-title-olp {
    font-size: 35px !important;
  }

  .nav_btn.seo_btn_one {
    margin-left: 30px;
  }
}

@media (max-width: 650px) {
  .portfolio_filter .work_portfolio_item+.work_portfolio_item {
    margin-left: 15px;
  }

  .t_color3 br {
    display: none;
  }

  .company_widget .mt_40 {
    margin-top: 25px;
  }

  .f_widget h3.mb-30 {
    margin-bottom: 15px;
  }

  .menu>.nav-item.submenu.mega_menu>.dropdown-menu.show {
    padding: 0px 10px;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two>.dropdown-menu>.nav-item {
    padding: 15px 10px;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two>.dropdown-menu>.nav-item .item .text {
    font-size: 12px;
  }

  .seo_sec_title p br {
    display: none;
  }

  .seo_sec_title h2 {
    font-size: 30px;
    line-height: 40px;
  }

  .sect-pad-t-wva {
    padding: 0px 25px !important;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner>.dropdown-menu>.nav-item .dropdown-menu .nav-item {
    width: 33.33%;
  }

  .case-studies-area.grid-items .case-items.colums-3 .pf-item {
    width: 100% !important;
  }

  .case-items-area .pf-item .item .thumb:hover .info {
    padding: 10px !important;
  }

  .case-items-area .pf-item .item .thumb:hover .popup-gallery {
    top: 15px !important;
  }

  .ptb-120 {
    padding: 56px 0 !important;
  }

  .single-feature.p-5 {
    padding: 1.3rem !important;
  }

  .container .section-heading.text-center h2 {
    font-size: 1.5rem !important;
  }

  .styles-module_chatHeader__23UA6 .styles-module_statusTitle__1svrc {
    font-size: 15px !important;
  }

  .mt-5 {
    margin-top: 1rem !important;
  }
}

@media (max-width: 576px) {

  .footer_bottom .f_social_icon_two,
  .footer_bottom .f_menu {
    text-align: center !important;
  }

  .footer_bottom .f_social_icon_two {
    margin-bottom: 6px;
  }

  .banner_top h2 {
    font-size: 26px;
    line-height: 35px;
  }

  .breadcrumb_area.banner_top .container {
    height: 400px;
  }

  .breadcrumb_area.banner_top .contact_container {
    height: 270px;
  }

  .breadcrumb_area.banner_top .container_with_button {
    height: 270px;
  }

  .contactus_breadcrumb_mobile {
    height: 300px;
  }

  .breadcrumb_area .breadcrumb_shap {
    right: 0%;
  }

  .l_height60,
  .l_height50 {
    line-height: 45px;
  }

  .f_size_30 {
    font-size: 24px;
    line-height: 35px;
  }

  .f_size_40 {
    font-size: 30px;
  }

  .breadcrumb_area .breadcrumb_content .breadcrumb_content_title {
    font-size: 25px;
    line-height: 25px;
    margin-bottom: 5px !important;
  }

  .breadcrumb_area .breadcrumb_content .breadcrumb_content_description {
    line-height: 25px;
  }

  .portfolio_filter {
    text-align: center;
    margin-bottom: 30px;
  }

  .portfolio_filter .work_portfolio_item {
    margin-bottom: 5px;
  }

  .portfolio_gallery .portfolio_item .portfolio_img img {
    width: 100%;
  }

  .app_testimonial_area .nav_container .owl-prev,
  .app_testimonial_area .nav_container .owl-next {
    display: none;
  }

  .sec_title h2.mb_20 {
    margin-bottom: 10px;
  }

  .shop_grid_area .row,
  .shop_list_area .row {
    text-align: center;
  }

  .blog_content .post_date {
    top: -60px;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two>.dropdown-menu .nav-item {
    width: calc(100% / 2);
  }

  .seo_sec_title.mb_70 {
    margin-bottom: 40px;
  }

  .seo_service_item {
    padding-left: 15px;
    padding-right: 15px;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner>.dropdown-menu>.nav-item .dropdown-menu .nav-item {
    width: 50%;
  }

  .hosting_title h2 {
    font-size: 31px;
    line-height: 40px;
  }

  .seo_service_card .seo_service_item {
    min-height: 270px;
  }

  .company_logo {
    margin: 0 15px;
  }

  .company_logo img {
    width: 100% !important;
  }

  .styles-module_chatHeader__23UA6 .styles-module_statusTitle__1svrc {
    font-size: 14px !important;
  }

  .eoVzRM {
    right: 0.9rem !important;
  }
}

@media (max-height: 420px) {
  .navbar-collapse {
    max-height: 310px;
  }

  .styles-module_chatHeader__23UA6 .styles-module_statusTitle__1svrc {
    font-size: 15px !important;
  }

  .eoVzRM {
    right: 0.9rem !important;
  }
}

@media (max-width: 420px) {
  .breadcrumb_area .breadcrumb_shap {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .navbar_logo {
    width: 50px;
  }

  .banner_top {
    padding-top: 70px;
  }

  .breadcrumb_area.banner_top .container {
    height: 450px;
  }

  .contact_breadcrumb_content_container {
    height: 320px;
  }

  .breadcrumb_area.banner_top .contact_container {
    height: 320px;
  }

  .breadcrumb_area.banner_top .container_with_button {
    height: 370px;
  }

  .contactus_breadcrumb_mobile {
    height: 300px;
  }

  .custom_breadcrumb .breadcrumb {
    font-size: 12px;
  }

  .breadcrumb_area .breadcrumb_content .breadcrumb_content_title {
    font-size: 21px;
  }

  .header_area .container {
    padding-left: 15px;
    padding-right: 15px;
  }

  .home_service_slider .seo_service_card .seo_service_item {
    height: 290px;
  }
}

@media (max-width: 450px) {
  .menu>.nav-item.submenu.mega_menu.mega_menu_two>.dropdown-menu .nav-item {
    width: 250px;
  }

  .menu>.nav-item.submenu.mega_menu.mega_menu_two>.dropdown-menu>.nav-item .item .text {
    font-size: 16px;
  }

  .processcarditem {
    padding: 0 60px;
  }
}