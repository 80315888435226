@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900";

a {
  text-decoration: none !important;
}

a:hover,
a:focus {
  text-decoration: none;
}

.row.m0 {
  margin: 0;
}

.p0 {
  padding: 0;
}

i:before {
  margin-left: 0 !important;
}

body {
  font-size: 14px;
  font-weight: 300;
  line-height: 28px;
  font-family: "Open Sans";
  letter-spacing: 0;
  color: #677294;
  padding: 0;
  overflow-x: hidden;
  z-index: 0;
}

body::-webkit-scrollbar {
  display: none;
}

.body_wrapper {
  z-index: 20;
  overflow: hidden;
}

.text_align_center {
  text-align: center;
}

.text_align_right {
  text-align: right !important;
}

.text_align_left {
  text-align: left !important;
}

.item_center {
  display: flex;
  justify-content: center;
}

a,
.btn,
button {
  text-decoration: none;
  outline: none;
}

a:hover,
a:focus,
.btn:hover,
.btn:focus,
button:hover,
button:focus {
  text-decoration: none;
  outline: none;
}

.btn:focus,
.btn:active:focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn.active.focus {
  outline: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.seo_sec_title h2 {
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  color: #263b5e;
  margin-bottom: 15px;
}

.seo_sec_title p {
  font-size: 16px;
  font-weight: 300;
  color: #6a7695;
  margin-bottom: 0;
  max-width: 80%;
  margin-left: auto;
  margin-right: auto;
  white-space: pre-line;
}

@media (min-width: 1250px) {
  .container {
    max-width: 1200px;
  }
}

@media (max-width: 767px) {
  .container {
    max-width: 100%;
  }
}

.none {
  display: none !important;
}

.container.custom_container {
  max-width: 1520px;
}

.f_size_50 {
  font-size: 50px;
}

.f_size_45 {
  font-size: 45px;
}

.f_size_40 {
  font-size: 40px;
}

.f_size_30 {
  font-size: 30px;
}

.f_size_23 {
  font-size: 23px;
}

.f_size_28 {
  font-size: 28px;
}

.f_size_22 {
  font-size: 22px;
}

.f_size_24 {
  font-size: 24px;
}

.f_size_20 {
  font-size: 20px;
}

.f_size_18 {
  font-size: 18px;
}

.f_size_16 {
  font-size: 16px;
}

.f_size_15 {
  font-size: 15px;
}

.f_size_14 {
  font-size: 14px;
}

.f_size_12 {
  font-size: 12px !important;
}

.l_height60 {
  line-height: 60px;
}

.l_height50 {
  line-height: 50px;
}

.l_height45 {
  line-height: 45px;
}

.l_height40 {
  line-height: 40px;
}

.l_height30 {
  line-height: 30px;
}

.l_height34 {
  line-height: 34px;
}

.l_height28 {
  line-height: 28px;
}

.f_p {
  font-family: "Poppins", sans-serif;
}

.f_300 {
  font-weight: 300;
}

.f_400 {
  font-weight: 400;
}

.f_500 {
  font-weight: 500;
}

.f_600 {
  font-weight: 600 !important;
}

.f_700 {
  font-weight: 700;
}

.f_900 {
  font-weight: 900;
}

.t_color {
  color: #051441;
}

.w_color {
  color: #fff;
}

.t_color2 {
  color: #3f4451;
}

.t_color3 {
  color: #222d39;
}

.d_p_color {
  color: #bdbed6;
}

.bg_color {
  background: #fbfbfd;
}

.dk_bg_one {
  background: #090a21;
}

.dk_bg_two {
  background: #0f1029;
}

.seo_title_color {
  color: #263b5e;
}

.mt-120 {
  margin-top: -120px !important;
}

.mt-60 {
  margin-top: -60px !important;
}

.mt-50 {
  margin-top: -50px !important;
}

.mt_35 {
  margin-top: 35px;
}

.mt_5px {
  margin-top: 5px;
}

.mb_5px {
  margin-bottom: 5px;
}

.mt_10 {
  margin-top: 10px;
}

.mt_20 {
  margin-top: 20px;
}

.mt_60 {
  margin-top: 60px;
}

.mt_30 {
  margin-top: 30px;
}

.mt_40 {
  margin-top: 40px;
}

.mt_50 {
  margin-top: 50px;
}

.mt_70 {
  margin-top: 70px;
}

.mt_75 {
  margin-top: 75px;
}

.mt_100 {
  margin-top: 100px;
}

.mt_130 {
  margin-top: 130px;
}

.mb_70 {
  margin-bottom: 70px !important;
}

.mb_20 {
  margin-bottom: 20px;
}

.mb_25 {
  margin-bottom: 25px;
}

.mb_12 {
  margin-bottom: 12px;
}

.mb_15 {
  margin-bottom: 15px;
}

.mb_30 {
  margin-bottom: -30px;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb_40 {
  margin-bottom: 40px;
}

.mb_50 {
  margin-bottom: 50px !important;
}

.mb-50 {
  margin-bottom: -50px;
}

.mb_60 {
  margin-bottom: 60px;
}

.mb_90 {
  margin-bottom: 90px;
}

.mb_120 {
  margin-bottom: 120px;
}

.ml_10 {
  margin-left: 10px;
}

.ml-30 {
  margin-left: 30px;
}

.ml-60 {
  margin-left: 60px;
}

.mr_10 {
  margin-right: 10px;
}

.mlr-100 {
  margin: 0 100px;
}

.pl_10 {
  padding-left: 10px;
}

.pl_20 {
  padding-left: 20px;
}

.pl-30 {
  padding-left: 30px;
}

.pl_40 {
  padding-left: 40px;
}

.pl_50 {
  padding-left: 50px;
}

.pl_100 {
  padding-left: 100px;
}

.pl_70 {
  padding-left: 70px;
}

.pl_120 {
  padding-left: 120px;
}

.pr_100 {
  padding-right: 100px;
}

.pr_120 {
  padding-right: 120px;
}

.pr_70 {
  padding-right: 70px;
}

.pr_50 {
  padding-right: 50px;
}

.pr_20 {
  padding-right: 20px;
}

.pl-pr_50 {
  padding: 0 50px;
}

.pl-pr_100 {
  padding: 0 100px;
}

.pl-pr_10 {
  padding-left: 10px;
  padding-right: 10px;
}

.pl-pr_30 {
  padding-left: 30px;
  padding-right: 30px;
}

.pa_25 {
  padding: 25px !important;
}

.pa_10 {
  padding: 10px !important;
}

.pt_150 {
  padding-top: 150px;
}

.pb_20 {
  padding-bottom: 20px !important;
}

.pt_120 {
  padding-top: 40px;
}

.pb-120 {
  padding-bottom: 120px;
}

.ptb-120 {
  padding: 120px 0;
}

.pt-pb_20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.sec_pad {
  padding: 50px 0;
}

.btn_hover {
  overflow: hidden;
  display: inline-block;
  font-weight: 500;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  z-index: 1;
  cursor: pointer;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.btn_hover:hover {
  color: #fff;
}

.btn_three {
  font: 500 14px Poppins, sans-serif;
  color: #fff;
  background: #7444fd;
  border-radius: 3px;
  padding: 15px 30px;
  border: 1px solid #7444fd;
  margin-top: 50px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  display: inline-block;
}

.btn_three:hover {
  color: #7444fd;
  background: transparent;
}

.nav_btn.seo_btn_one {
  width: fit-content;
  display: flex;
  background: #fff;
  border: 2px solid #2c6deb;
  font-size: 15px;
  padding: 10px 30px;
}

.seo_btn {
  display: inline-block;
  padding: 11px 40px;
  border-radius: 4px;
}

.seo_btn_one {
  color: #2c6deb;
  background: #e8e1fc;
}

.seo_btn_one:hover {
  background: #2c6deb;
  color: #fff;
}

@-webkit-keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@keyframes spinner {
  to {
    -webkit-transform: rotateZ(360deg);
    transform: rotateZ(360deg);
  }
}

@-webkit-keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@keyframes letters-loading {

  0%,
  75%,
  100% {
    opacity: 0;
    -webkit-transform: rotateY(-90deg);
    transform: rotateY(-90deg);
  }

  25%,
  50% {
    opacity: 1;
    -webkit-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
}

@media screen and (max-width: 767px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 8em;
    width: 8em;
  }

  .ctn-preloader .animation-preloader .txt-loading {
    font: bold 3.5em Poppins, sans-serif;
  }
}

@media screen and (max-width: 500px) {
  .ctn-preloader .animation-preloader .spinner {
    height: 7em;
    width: 7em;
  }

  .ctn-preloader .animation-preloader .txt-loading {
    font: bold 2em Poppins, sans-serif;
  }
}

.header_area {
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 20;
  top: 0;
}

.menu_one {
  padding: 0;
}

.navbar_logo {
  width: 80px;
}

.abt_contact_info {
  width: 30%;
  margin: auto;
  margin-left: 35px;
  margin-right: -25px;
}

.drop_t_des {
  color: #7a7a7a !important;
}

.contact_info_text {
  color: #7a7a7a !important;
  font-weight: 600 !important;
  font-size: 13px !important;
}

.technology_drop_head {
  color: #3f3f3f !important;
  font-weight: 600 !important;
}

.about_dropdown {
  width: 94% !important;
  margin: auto;
  justify-content: center;
}

.abt_ul {
  width: 100%;
  padding: 0 !important;
  margin: 0 !important;
}

.abt_ul .nav-item .dropdown-menu {
  margin: 0 !important;
}

.abt_list_item {
  border: solid #eaeaea 1px;
  width: 50% !important;
  padding: 30px 40px !important;
}

.abt_nav_icon .abt_drop_item {
  display: flex !important;
  width: fit-content;
  margin-right: 10px;
}

.abt_nav_icon .text {
  margin-top: 0 !important;
}

.abt_nav_icon .img img {
  width: 90px !important;
}

.abt_contact_info .dropdown-menu .nav-item {
  width: 100% !important;
  margin-right: -20px !important;
  padding: 5px !important;
}

.abt_contact_info .dropdown-menu .nav-item img {
  width: 30px;
  margin-right: 10px;
}

.dropdown_discuss {
  text-align: left;
  font-size: 18px !important;
  margin-top: 10px !important;
  font-weight: 600 !important;
}

.dropdown_discuss_des {
  text-align: left;
  font-size: 12px !important;
  margin-top: 5px !important;
  font-weight: 600 !important;
  color: #9d9696 !important;
}

.navbar-brand img {
  display: none;
}

.navbar-brand img+img {
  display: block;
}

.sticky_logo img {
  display: block;
}

.sticky_logo img+img {
  display: none;
}

.menu>.nav-item {
  position: relative;
  padding-bottom: 35px;
  padding-top: 35px;
}

.menu>.nav-item>.nav-link {
  font: 500 15px Poppins, sans-serif;
  color: #051441;
  padding: 0;
  -webkit-transition: color 0.3s ease 0;
  -o-transition: color 0.3s ease 0;
  transition: color 0.3s ease 0;
  position: relative;
  cursor: pointer;
}

.menu>.nav-item>.nav-link:before {
  content: "";
  width: 0;
  height: 1px;
  background: #2c6deb;
  position: absolute;
  bottom: 0;
  left: auto;
  right: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.menu>.nav-item>.nav-link:after {
  display: none;
}

.menu>.nav-item.submenu .dropdown-menu {
  margin: 0;
  border: 0;
  margin: 0;
  border-radius: 0;
  left: -40px;
  min-width: 200px;
  padding: 0;
  background: #fff;
  -webkit-box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
  box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu .dropdown-menu {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
}

.menu>.nav-item.submenu .dropdown-menu .nav-item {
  display: block;
  width: 100%;
  margin-right: 0;
  padding: 0 40px;
  position: relative;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link {
  padding: 0;
  white-space: nowrap;
  font: 400 14px/34px Poppins, sans-serif;
  color: #051441;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link:after {
  display: none;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item .nav-link span.arrow_carrot-right {
  right: 20px;
  position: absolute;
  line-height: 30px;
  font-size: 18px;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item:first-child {
  padding-top: 25px;
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu .dropdown-menu .nav-item:first-child>.dropdown-menu {
    top: 0;
  }
}

.menu>.nav-item.submenu .dropdown-menu .nav-item:last-child {
  padding-bottom: 30px;
  margin-bottom: -8px;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item>.dropdown-menu {
  -webkit-transition: -webkit-transform 0.3s ease-in;
  transition: -webkit-transform 0.3s ease-in;
  -o-transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in;
  transition: transform 0.3s ease-in, -webkit-transform 0.3s ease-in;
  padding: 0;
}

.menu>.nav-item.submenu .dropdown-menu .nav-item>.dropdown-menu:before {
  display: none;
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu .dropdown-menu .nav-item>.dropdown-menu {
    position: absolute;
    left: 100%;
    top: -25px;
    opacity: 0;
    display: block;
    visibility: hidden;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item {
  padding: 0;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu {
  left: 0;
  position: relative;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item {
  padding: 20px 45px;
  border: 1px solid transparent;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item .nav-link .navdropdown_link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item .nav-link .navdropdown_link .navdropdown_icon {
  margin-right: 25px;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item .nav-link .navdropdown_link .navdropdown_icon img {
  max-width: 30px;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item .nav-link .navdropdown_link .navdropdown_content h5 {
  font: 500 16px Poppins, sans-serif;
  color: #13112d;
  margin-bottom: 0;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item .nav-link .navdropdown_link .navdropdown_content p {
  margin-bottom: 0;
  padding-top: 5px;
  font-size: 15px;
  line-height: 20px;
  color: #677294;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item.nav_download_btn {
  border-top-color: #f2f0f7;
  padding: 15px 45px;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item.nav_download_btn .nav-link .navdropdown_link {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item.nav_download_btn .nav-link .navdropdown_link .navdropdown_icon {
  margin-right: 10px;
}

.menu>.nav-item.submenu .dropdown-menu.mega_menu_three>.nav-item>.dropdown-menu .nav-item:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  border-color: #f2f0f7;
  background-color: #fff;
  -webkit-box-shadow: 0 20px 30px 0 rgba(12, 0, 46, 0.1),
    0 -1px 0 0 rgba(243, 241, 248, 0.004);
  box-shadow: 0 20px 30px 0 rgba(12, 0, 46, 0.1),
    0 -1px 0 0 rgba(243, 241, 248, 0.004);
}

.menu>.nav-item.submenu.mega_menu {
  position: static;
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner {
  min-width: 200px;
  list-style: none;
  position: absolute;
  -webkit-box-shadow: -1px 2px 19px 3px rgba(14, 0, 40, 0.05);
  box-shadow: -1px 15px 19px 3px rgba(14, 0, 40, 0.05);
  left: 0;
  right: 0;
  -webkit-transition: all 300ms linear 0;
  -o-transition: all 300ms linear 0;
  transition: all 300ms linear 0;
  background: #fff;
  border-radius: 0px 0px 10px 10px;
  overflow: hidden;
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu.mega_menu .mega_menu_inner {
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
    -webkit-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    display: block;
  }
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu {
  position: relative;
  max-width: 1170px;
  margin: 0 auto;
  left: 0;
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-bottom: 0;
  width: 25%;
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item>.nav-link {
  font: 500 18px Poppins, sans-serif;
  color: #13112d;
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item .dropdown-menu {
  left: 0;
  position: relative;
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
  -webkit-box-shadow: none;
  box-shadow: none;
  padding: 0;
  background: transparent;
  top: 0;
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item .dropdown-menu>.nav-item {
  padding-left: 0;
  padding-right: 0;
  white-space: normal;
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item .dropdown-menu>.nav-item:first-child {
  padding-top: 20px;
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item .dropdown-menu>.nav-item:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.menu>.nav-item.submenu.mega_menu .mega_menu_inner>.dropdown-menu>.nav-item:hover>.nav-link {
  color: inherit !important;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu {
  left: 0;
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu {
    padding: 20px 0;
  }
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .mCSB_scrollTools {
  width: 0;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .mCSB_container {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .mCustomScrollBox {
  width: 100%;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item {
  padding: 0;
  margin-bottom: 0;
  width: 100%;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .dropdown-menu {
  left: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -20px;
  margin-right: -20px;
}

@media (max-height: 980px) and (min-width: 992px) {
  .menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .dropdown-menu {
    max-height: 500px;
    overflow-y: scroll;
  }

  .dropdown-menu::-webkit-scrollbar {
    display: none;
  }
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .dropdown-menu .nav-item {
  width: 25%;
  padding: 15px 20px;
  margin-bottom: 0;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item {
  text-align: center;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .rebon_tap {
  font: 500 12px Poppins, sans-serif;
  color: #fff;
  background: #f91f43;
  display: block;
  position: absolute;
  top: -7px;
  left: -25px;
  padding: 15px 22px 5px;
  text-transform: uppercase;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .img {
  -webkit-box-shadow: 0 4px 8px 0 rgba(12, 0, 46, 0.06);
  box-shadow: 0 4px 8px 0 rgba(12, 0, 46, 0.06);
  position: relative;
  margin-top: 0;
  display: block;
  overflow: hidden;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .img:before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: #13112d;
  left: 0;
  top: 0;
  opacity: 0;
  -webkit-transition: opacity 0.3s linear;
  -o-transition: opacity 0.3s linear;
  transition: opacity 0.3s linear;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .img img {
  width: 100%;
  max-width: 100%;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .img.box_none {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .text {
  font: 500 16px/22px Poppins, sans-serif;
  color: #13112d;
  display: block;
  margin-top: 20px;
}

.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item:hover .img:before {
  opacity: 0.5;
}

@media (min-width: 992px) {
  .menu>.nav-item.submenu.mega_menu:hover .dropdown-menu>.nav-item>.dropdown-menu {
    opacity: 1;
    visibility: visible;
  }

  .menu>.nav-item.submenu.mega_menu:hover .mega_menu_inner {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
  }
}

.menu>.nav-item+.nav-item {
  margin-left: 35px;
}

.menu>.nav-item .nav-link.active:before {
  width: 100%;
  left: 0;
  right: auto;
}

.menu>.nav-item:hover .nav-link:before {
  width: 100%;
  left: 0;
  right: auto;
}

@media (min-width: 992px) {
  .menu>.nav-item:hover .dropdown-menu {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    visibility: visible;
  }

  .menu>.nav-item:hover .dropdown-menu.mega_menu_three .nav-item .dropdown-menu {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 992px) and (min-width: 992px) {
  .menu>.nav-item:hover .dropdown-menu>.nav-item:hover .dropdown-menu {
    -webkit-transform: scaleY(1);
    -ms-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
    visibility: visible;
  }
}

.navbar_new {
  background: #fff !important;
  color: #051441;
  box-shadow: 0 4px 6px 0 rgb(12 0 46 / 6%);
}

.menu_four .menu_toggle .hamburger span,
.menu_four .menu_toggle .hamburger-cross span {
  background: #2c6deb;
}

.navbar-toggler {
  padding: 0;
  margin-left: 20px;
}

.navbar-toggler:focus {
  outline: none;
}

.menu_toggle {
  width: 22px;
  height: 22px;
  position: relative;
  cursor: pointer;
  display: block;
}

.menu_toggle .hamburger {
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
}

.menu_toggle .hamburger span {
  width: 0;
  height: 2px;
  position: relative;
  top: 0;
  left: 0;
  margin: 4px 0;
  display: block;
  background: #2c6deb;
  border-radius: 3px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.menu_toggle .hamburger span:nth-child(1) {
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

.menu_toggle .hamburger span:nth-child(2) {
  -webkit-transition-delay: 0.125s;
  -o-transition-delay: 0.125s;
  transition-delay: 0.125s;
}

.menu_toggle .hamburger span:nth-child(3) {
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.menu_toggle .hamburger-cross {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
}

.menu_toggle .hamburger-cross span {
  display: block;
  background: #2c6deb;
  border-radius: 3px;
  -webkit-transition: 0.2s ease-in-out;
  -o-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
}

.menu_toggle .hamburger-cross span:nth-child(1) {
  height: 100%;
  width: 2px;
  position: absolute;
  top: 0;
  left: 10px;
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.menu_toggle .hamburger-cross span:nth-child(2) {
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  top: 10px;
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.collapsed .menu_toggle .hamburger span {
  width: 100%;
}

.collapsed .menu_toggle .hamburger span:nth-child(1) {
  -webkit-transition-delay: 0.3s;
  -o-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

.collapsed .menu_toggle .hamburger span:nth-child(2) {
  -webkit-transition-delay: 0.4s;
  -o-transition-delay: 0.4s;
  transition-delay: 0.4s;
}

.collapsed .menu_toggle .hamburger span:nth-child(3) {
  -webkit-transition-delay: 0.5s;
  -o-transition-delay: 0.5s;
  transition-delay: 0.5s;
}

.collapsed .menu_toggle .hamburger-cross span:nth-child(1) {
  height: 0;
  -webkit-transition-delay: 0;
  -o-transition-delay: 0;
  transition-delay: 0;
}

.collapsed .menu_toggle .hamburger-cross span:nth-child(2) {
  width: 0;
  -webkit-transition-delay: 0.2s;
  -o-transition-delay: 0.2s;
  transition-delay: 0.2s;
}

.header_top .contact_number li {
  margin-right: 24px;
}

.header_top .footer-social-media li {
  margin-right: 15px;
  border-radius: 50%;
  border: 1px solid #fff;
  display: flex;
}

.header_top .footer-social-media li:hover {
  border: 1px solid #2c6deb;
}

.header_top .footer-social-media li a {
  padding: 3px;
  border-radius: 50%;
  box-shadow: 0 10px 15px 0 rgb(0 0 0 / 7%);
  -webkit-box-shadow: 0 10px 15px 0 rgb(0 0 0 / 7%);
  -moz-box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.07);
  -webkit-transition: 0.2s ease;
  transition: 0.2s ease;
}

.header_top .footer-social-media li a img {
  width: 18px;
  filter: grayscale(1);
}

.header_top .footer-social-media li:hover a img {
  filter: grayscale(0);
}

.header_top .contact_number li a {
  color: #7e7e7e;
  font-weight: 700;
  font-size: 12px;
}

.header_top .contact_number li img {
  width: 16px;
  margin-right: 5px;
}

.header_area {
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.navbar_fixed .header_area {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #fff;
  -webkit-box-shadow: 0 4px 6px 0 rgba(12, 0, 46, 0.06);
  box-shadow: 0 4px 6px 0 rgba(12, 0, 46, 0.06);
}

.navbar_fixed .header_area .menu_one {
  padding: 0;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}

.navbar_fixed .header_area .sticky_logo img {
  display: none;
}

.navbar_fixed .header_area .sticky_logo img+img {
  display: block;
}

.navbar_fixed .header_area .w_menu .nav-item .nav-link:before {
  background: #2c6deb;
}

.navbar_fixed .header_area .menu_four .btn_get_radious {
  border-color: #563bd1;
  color: #563bd1;
}

.navbar_fixed .header_area .menu_four .btn_get_radious:hover {
  background: #563bd1;
  color: #fff;
}

.navbar_fixed .header_area .menu_four .menu_toggle .hamburger span,
.navbar_fixed .header_area .menu_four .menu_toggle .hamburger-cross span {
  background: #2c6deb;
}

.banner_top {
  padding-top: 93px;
}

.banner_top h2 {
  color: #051441;
  margin-bottom: 25px;
}

.banner_top p {
  margin-bottom: 15 px;
}

@-webkit-keyframes animateUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes animateUpDown {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }

  50% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.seo_home_area {
  background: #f7fdfc;
  position: relative;
}

.seo_banner_content h2 {
  font-size: 50px;
  line-height: 60px;
  color: #263b5e;
  font-weight: 600;
}

.seo_banner_content p {
  font-size: 16px;
  line-height: 25px;
  color: #6a7695;
  margin-bottom: 53px;
}

.seo_banner_content .seo_btn {
  margin: 0 8px;
}

@-webkit-keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

.breadcrumb_area {
  background: #eef1f6;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.breadcrumb_area .breadcrumb {
  background: transparent;
  justify-content: flex-end;
  margin: 0;
}

.breadcrumb_area .breadcrumb_content .breadcrumb_content_heading {
  color: #2c6deb;
  font-size: 18px !important;
}

.breadcrumb_area .breadcrumb_content .breadcrumb_content_title {
  color: #181818;
  font-size: 30px;
}

.breadcrumb_area .breadcrumb_content .breadcrumb_content_description {
  color: #575757;
  width: 50%;
}

.breadcrumb_area .breadcrumb .breadcrumb-item {
  color: #2c6deb;
  font-weight: 500;
}

.breadcrumb_content_container .read-more-btn {
  font-size: 14px;
  position: relative;
  cursor: pointer;
  min-height: 40px;
  /* background: -webkit-gradient(linear,left top,left bottom,color-stop(1%,#4842a8),to(#524bb8)); */
  background: #010101;
  border-radius: 2px;
  transition: all 0.2s ease-in;
  max-width: 136px;
  margin-bottom: 20px;
}

.breadcrumb_content_container .read-more-btn a {
  color: #fff;
  margin-left: 0 !important;
  padding: 0px 10px;
}

.breadcrumb_area.banner_top .container {
  height: 280px;
}

.contactus_breadcrumb_mobile {
  height: 280px;
}

.breadcrumb_area .breadcrumb_shap {
  position: absolute;
  right: 17%;
  bottom: 0;
  z-index: -1;
  width: 390px;
}

.breadcrumb_area p {
  max-width: 580px;
}

.custom_breadcrumb {
  font-size: 14px;
  margin-bottom: -16px;
}

.custom_breadcrumb .breadcrumb {
  background-color: transparent;
  color: #2c6deb;
}

.seo_service_item h4:hover,
.seo_industry_item h4:hover,
.custom_breadcrumb .breadcrumb-item .breadcrumb-link,
.custom_breadcrumb .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb_area .breadcrumb-item+.breadcrumb-item::before {
  color: #2c6deb !important;
}

.software_featured_area_two {
  background: #2c6deb;
}

@-webkit-keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

@keyframes pulsate {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.5);
    transform: translate(-50%, -50%) scale(0.5);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(4);
    transform: translate(-50%, -50%) scale(4);
  }
}

.industry_slider {
  overflow: hidden !important;
}

.industry_slider .slick-list,
.service_slider .slick-list {
  overflow: visible !important;
}

.software_service_area {
  background: #f9f9fc;
}

.seo_service_info {
  display: block;
}

.seo_service_card {
  margin: 0 10px;
  padding: 0 15px;
}

.seo_service_card .seo_service_item>img {
  width: 60px;
  margin: 10px auto;
}

.seo_service_card .seo_service_item {
  min-height: 290px;
}

.seo_industry_card .seo_industry_item {
  margin: 0 15px;
  min-height: 180px;
}

.seo_service_item {
  background-color: #fff;
  min-height: 300px;
  -webkit-box-shadow: 0 3px 10px 0 rgba(38, 59, 94, 0.1);
  box-shadow: 0 3px 10px 0 rgba(38, 59, 94, 0.1);
  padding: 20px 23px 25px;
  border-radius: 10px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-align: center;
}

.seo_service_item h4 {
  font-size: 20px;
  line-height: 28px;
  color: #263b5e;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 10px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.seo_service_item p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 25px;
  color: #6a7695;
}

.seo_service_item i {
  font-size: 40px;
  color: #afb8ce;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.seo_service_item i:hover {
  color: #5e2ced;
}

.seo_service_item:hover {
  -webkit-box-shadow: 0 30px 60px 0 rgba(38, 59, 94, 0.1);
  box-shadow: 0 30px 60px 0 rgba(38, 59, 94, 0.1);
}

.cloud_slider>.slick-prev:before,
.cloud_slider>.slick-next:before {
  font-family: "slick";
  font-size: 37px !important;
  line-height: 1;
  opacity: 0.75;
  color: #1c233a;
  -webkit-font-smoothing: antialiased;
}

.aisolution_item {
  background-color: #fff;
  min-height: 285px;
  -webkit-box-shadow: 0 3px 10px 0 rgba(38, 59, 94, 0.1);
  box-shadow: 0 3px 10px 0 rgba(38, 59, 94, 0.1);
  padding: 20px 23px 25px;
  border-radius: 10px;
  margin-bottom: 30px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  text-align: center;
}

.aisolution_item h5 {
  font-size: 20px;
  line-height: 28px;
  color: #263b5e;
  font-weight: 500;
  margin-top: 12px;
  margin-bottom: 10px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.aisolution_item h5:hover {
  color: #5e2ced;
}

.aisolution_item p {
  margin-bottom: 0;
  font-size: 16px;
  line-height: 25px;
  color: #6a7695;
}

.aisolution_item i {
  font-size: 40px;
  color: #afb8ce;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.aisolution_item i:hover {
  color: #5e2ced;
}

.aisolution_item:hover {
  -webkit-box-shadow: 0 30px 60px 0 rgba(38, 59, 94, 0.1);
  box-shadow: 0 30px 60px 0 rgba(38, 59, 94, 0.1);
}

.aisolution_item img {
  width: 60px;
}

.seo_industry_item img {
  width: 60px;
  margin: 10px auto;
}

.seo_industry_item {
  background-color: #fff;
  min-height: 150px;
  box-shadow: 0 3px 10px 0 #610000;
  padding: 20px 15px 15px;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: all 0.3s linear;
  cursor: pointer;
  -webkit-box-shadow: 0 3px 10px 0 rgba(38, 59, 94, 0.1);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  text-align: center;
}

.seo_industry_item h4 {
  font-size: 18px;
  line-height: 25px;
  color: #263b5e;
  font-weight: 500;
  margin-top: 15px;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.seo_industry_item p {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 30px;
  color: #6a7695;
}

.seo_industry_item i {
  font-size: 40px;
  color: #afb8ce;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.seo_industry_item i:hover {
  color: #5e2ced;
}

.seo_industry_item:hover {
  -webkit-box-shadow: 0 30px 60px 0 rgba(38, 59, 94, 0.1);
  box-shadow: 0 30px 60px 0 rgba(38, 59, 94, 0.1);
}

.industry_we_serve .seo_sec_title h2,
.industry_we_serve .seo_sec_title p {
  color: #fff;
}

.case_studies_slider {
  margin-left: -15px;
  margin-right: -15px;
}

.case_studies_slider .slick-dots {
  margin-top: 40px;
  position: relative;
  bottom: 0;
}

.case_studies_slider .slick-dots li {
  margin: 0;
}

.case_studies_slider .slick-dots li button {
  width: 6px;
  height: 6px;
  background: #b9c4d8;
  border: 0;
}

.case_studies_slider .slick-dots li.slick-active button {
  background: white;
}

.studies_item {
  background: #fff;
  -webkit-box-shadow: 0 3px 4px 0 rgba(38, 59, 94, 0.1);
  box-shadow: 0 3px 4px 0 rgba(38, 59, 94, 0.1);
  margin: 10px 15px;
}

.studies_item img {
  max-width: 100%;
}

.studies_item .text {
  padding: 18px 40px;
}

.studies_item .text h4 {
  font-size: 20px;
  color: #263b5e;
  font-weight: 500;
  margin-bottom: 3px;
}

.studies_item .text h4:hover {
  color: #5e2ced;
}

.studies_item .text p {
  margin-bottom: 0;
}

.studies_item .text p a {
  font-size: 16px;
  color: #6a7695;
}

.tab-content .tab-pane .tab_img img {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  max-width: 100%;
}

.tab-content .tab-pane.show .tab_img img {
  -webkit-transform: translateX(0);
  -ms-transform: translateX(0);
  transform: translateX(0);
}

.tab-content .tab_img {
  margin-right: -160px;
  padding-left: 120px;
  max-width: 100%;
}

.about_btn {
  font: 500 14px Poppins, sans-serif;
  padding: 17px 28px;
  background: #fbfbfd;
  -webkit-box-shadow: 0 20px 30px 0 rgba(12, 0, 46, 0.1);
  box-shadow: 0 20px 30px 0 rgba(12, 0, 46, 0.1);
  border-radius: 3px;
  color: #5e2ced;
  display: inline-block;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.about_btn:hover {
  color: #fff;
  background: #5e2ced;
}

.partner_company {
  display: flex !important;
  height: 100px;
  justify-content: center;
  align-items: center;
}

.company_logo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 30px;
}

.company_logo img {
  width: 70%;
  margin: 0 auto;
}

.slick-dots {
  text-align: center;
  margin-top: 75px;
}

.slick-dots li {
  width: auto;
  height: auto;
}

.slick-dots li button {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #3d3e65;
  margin: 0 5px;
  padding: 4px;
  border: 1px solid #3d3e65;
  display: block;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.slick-dots li button:before {
  display: none;
}

.slick-dots li.slick-active button {
  background: transparent;
  -webkit-transform: scale(1.5);
  -ms-transform: scale(1.5);
  transform: scale(1.5);
  border-color: #3d57f4;
}

.owl-dots .owl-dot:focus {
  outline: none;
}

.app_testimonial_area {
  background: #fbfbfd;
  padding-top: 80px;
  padding-bottom: 50px;
  overflow: hidden;
  position: relative;
}

.app_testimonial_area .text_shadow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.app_testimonial_area .text_shadow:before {
  content: attr(data-line);
  position: absolute;
  left: 0;
  width: 100%;
  text-align: center;
  color: #f6f6fa;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  font-size: 200px;
  line-height: 200px;
  z-index: -1;
  text-transform: uppercase;
  top: 50%;
  opacity: 0.9;
}

.app_testimonial_slider {
  max-width: 690px;
  margin: 0 auto;
}

.nav_container {
  position: relative;
  z-index: 1;
}

.nav_container .prev,
.nav_container .next {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #fff;
  -webkit-box-shadow: 0 3px 4px 0 rgba(12, 0, 46, 0.06);
  box-shadow: 0 3px 4px 0 rgba(12, 0, 46, 0.06);
  font-size: 20px;
  color: #222d39;
  text-align: center;
  border: 0;
  line-height: 50px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  cursor: pointer;
  z-index: 1;
}

.nav_container .prev:hover,
.nav_container .next:hover {
  background: #4069eb;
  color: #fff;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.nav_container .prev {
  left: 15px;
}

.nav_container .next {
  right: 15px;
}

.nav_container .slick-dots {
  margin-top: 30px;
}

.nav_container .slick-dots li button {
  background: #cfcfe7;
  border: 0;
}

.nav_container .slick-dots li.slick-active button {
  background: #653cee;
}

.app_testimonial_item .author-img {
  width: 70px;
  height: 70px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 15px;
}

.app_testimonial_item .author-img img {
  width: auto;
  border-radius: 100%;
  max-width: 100%;
}

.app_testimonial_item .author_info {
  margin-bottom: 35px;
}

.app_testimonial_item .author_info p {
  color: #959cb1;
  font-size: 14px;
  margin-bottom: 0;
}

.testimonial_title {
  position: relative;
  padding-bottom: 90px;
}

.testimonial_title h6 {
  font-size: 16px;
  color: #563bd1;
  font-family: "Poppins", sans-serif;
  font-style: italic;
}

.testimonial_title .prev,
.testimonial_title .next {
  position: absolute;
  bottom: 0;
  width: 50px;
  height: 50px;
  border: 1px solid #e0e4ed;
  text-align: center;
  font-size: 14px;
  color: #677294;
  border-radius: 50%;
  line-height: 48px;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
  cursor: pointer;
  background: transparent;
}

.testimonial_title .prev:hover,
.testimonial_title .next:hover {
  background: #563bd1;
  color: #fff;
}

.testimonial_title .next {
  left: 60px;
}

.seo_fact_area {
  background: #2c6deb;
  position: relative;
}

.faq_tab {
  border: 1px solid #f4f4f9;
  background-color: #fff;
  -webkit-box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.04);
  box-shadow: 0 2px 4px 0 rgba(12, 0, 46, 0.04);
  padding: 50px 40px;
}

.faq_tab .nav-tabs {
  border: 0;
  margin-bottom: 0;
  display: block;
}

.faq_tab .nav-item {
  margin: 0;
}

.faq_tab .nav-item .nav-link {
  font: 400 16px/22px Poppins, sans-serif;
  color: #677294;
  padding: 0;
  border: 0;
  border-radius: 0;
  display: inline-block;
  position: relative;
  margin-bottom: 20px;
}

.faq_tab .nav-item .nav-link:before {
  content: "";
  width: 0;
  height: 1px;
  background: #5e2ced;
  position: absolute;
  left: 0;
  bottom: 0;
  -webkit-transition: width 0.2s linear;
  -o-transition: width 0.2s linear;
  transition: width 0.2s linear;
}

.faq_tab .nav-item .nav-link.active {
  color: #5e2ced;
}

.faq_tab .nav-item .nav-link.active:before {
  width: 100%;
}

.faq_content .tab-pane .card {
  border: 0;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid #e4e0ee;
}

.faq_content .tab-pane .card .card-header {
  padding: 0;
  border: 0;
  background: transparent;
}

.faq_content .tab-pane .card .card-header .btn {
  color: #5e2ced;
  display: block;
  width: 100%;
  text-align: left;
  font: 500 18px/26px Poppins, sans-serif;
  padding: 20px 30px 20px 0;
  position: relative;
  white-space: normal;
}

.faq_content .tab-pane .card .card-header .btn i {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  display: none;
  font-size: 20px;
}

.faq_content .tab-pane .card .card-header .btn i+i {
  display: block;
}

.faq_content .tab-pane .card .card-header .btn.collapsed {
  color: #576370;
}

.faq_content .tab-pane .card .card-header .btn.collapsed i {
  display: block;
}

.faq_content .tab-pane .card .card-header .btn.collapsed i+i {
  display: none;
}

.faq_content .tab-pane .card .card-body {
  padding: 0 0 45px;
  font: 400 15px/26px Poppins, sans-serif;
  color: #677294;
}

.portfolio_area {
  overflow: hidden;
}

.portfolio_area .portfolio_filter {
  justify-content: flex-start;
}

.portfolio_filter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.portfolio_filter .work_portfolio_item {
  font: 400 16px/22px Poppins, sans-serif;
  color: #677294;
  display: inline-block;
  border-radius: 3px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  position: relative;
  cursor: pointer;
}

.portfolio_filter .work_portfolio_item:before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  background: transparent;
  position: absolute;
  left: auto;
  right: 0;
  -webkit-transition: width 0.3s linear;
  -o-transition: width 0.3s linear;
  transition: width 0.3s linear;
}

.portfolio_filter .work_portfolio_item.active,
.portfolio_filter .work_portfolio_item:hover {
  color: #2c6deb;
}

.portfolio_filter .work_portfolio_item.active:before,
.portfolio_filter .work_portfolio_item:hover:before {
  width: 100%;
  background: #2c6deb;
  right: auto;
  left: 0;
}

.portfolio_filter .work_portfolio_item+.work_portfolio_item {
  margin-left: 35px;
}

.portfolio_item .portfolio_img {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.portfolio_gallery .portfolio_item .portfolio_img img {
  max-width: 100%;
  width: 100%;
}

.portfolio_gallery .portfolio_item .portfolio_img .img_rounded {
  border-radius: 6px;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #14133b;
  opacity: 0;
  -webkit-transition: opacity 300ms linear;
  -o-transition: opacity 300ms linear;
  transition: opacity 300ms linear;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .img_popup {
  width: 50px;
  height: 50px;
  font-size: 18px;
  line-height: 50px;
  text-align: center;
  right: 50px;
  top: 50px;
  border: 1px solid #fff;
  border-radius: 50%;
  color: #fff;
  -webkit-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  opacity: 0;
  -webkit-transition: all 0.3s ease-in;
  -o-transition: all 0.3s ease-in;
  transition: all 0.3s ease-in;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .img_popup:hover {
  background: #fff;
  color: #282835;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description {
  bottom: 0;
  padding-left: 50px;
  padding-bottom: 48px;
  width: 100%;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description h3 {
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  -webkit-transition: all 0.4s linear;
  -o-transition: all 0.4s linear;
  transition: all 0.4s linear;
  color: #fff;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description .links {
  overflow: hidden;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description .links a {
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
  color: #fff;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content .portfolio-description .links a:before {
  background: #fff;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content.h_content_two .img_popup {
  right: 30px;
  top: 30px;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content.h_content_two .portfolio-description {
  padding-left: 30px;
  padding-bottom: 30px;
}

.portfolio_gallery .portfolio_item .portfolio_img .hover_content.h_content_two .portfolio-description .links a {
  font-size: 14px;
}

.portfolio_gallery .portfolio_item .portfolio_img:hover .hover_content {
  opacity: 0.6;
  display: flex;
}

.portfolio_gallery .portfolio_item .portfolio_img:hover img {
  filter: grayscale(90%) blur(10px) contrast(1.2) saturate(0.85);
}

.portfolio_gallery .portfolio_item .portfolio_img .arrow-right {
  display: none;
}

.portfolio_gallery .portfolio_item .portfolio_img:hover .arrow-right {
  filter: none;
  width: 35px;
  height: 100%;
  margin: auto;
  display: block;
}

.portfolio_gallery .portfolio_item .portfolio_img:hover .hover_content .img_popup {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.portfolio_gallery .portfolio_item .portfolio_img:hover .hover_content .portfolio-description h3,
.portfolio_gallery .portfolio_item .portfolio_img:hover .hover_content .portfolio-description a {
  -webkit-transform: translateY(0);
  -ms-transform: translateY(0);
  transform: translateY(0);
}

.portfolio-description .portfolio-title {
  overflow: hidden;
  display: inline-block;
}

.portfolio-description h3 {
  margin-top: 22px;
  margin-bottom: 0;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
  color: #282835;
}

.portfolio-description h3:hover {
  color: #5e2ced;
}

.portfolio-description .links {
  overflow: hidden;
}

.portfolio-description .links a {
  font-size: 15px;
  color: #677294;
  font-weight: 300;
  position: relative;
  display: inline-block;
  margin-right: 8px;
  -webkit-transition: color 0.01s linear;
  -o-transition: color 0.01s linear;
  transition: color 0.01s linear;
}

.portfolio-description .links a:before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  background: #5e2ced;
  position: absolute;
  left: auto;
  right: 0;
  -webkit-transition: width 0.3s linear;
  -o-transition: width 0.3s linear;
  transition: width 0.3s linear;
}

.portfolio-description .links a:hover {
  color: #5e2ced;
}

.portfolio-description .links a:hover:before {
  width: 100%;
  right: auto;
  left: 0;
}

.portfolio_fullwidth_area {
  padding-top: 100px;
}

.p_category_item .social_icon,
.icon .social_icon {
  padding-top: 8px;
}

.p_category_item .social_icon a,
.icon .social_icon a {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 13px;
  line-height: 43px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  margin-right: 5px;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
  color: #fff;
}

.p_category_item .social_icon a:nth-child(1),
.icon .social_icon a:nth-child(1) {
  background: #4779de;
}

.p_category_item .social_icon a:nth-child(2),
.icon .social_icon a:nth-child(2) {
  background: #e43e30;
}

.p_category_item .social_icon a:nth-child(3),
.icon .social_icon a:nth-child(3) {
  background: #1da1f3;
}

.p_category_item .social_icon a:hover,
.icon .social_icon a:hover {
  -webkit-box-shadow: 0 5px 12px 2px rgba(0, 11, 40, 0.2);
  box-shadow: 0 5px 12px 2px rgba(0, 11, 40, 0.2);
}

.portfolio_pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.portfolio_pagination .prev,
.portfolio_pagination .next {
  font-size: 14px;
  font-weight: 300;
  color: #9ca3b9;
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.portfolio_pagination .prev:hover,
.portfolio_pagination .next:hover {
  color: #222d39;
}

.portfolio_pagination .prev i {
  padding-right: 8px;
}

.portfolio_pagination .next i {
  padding-left: 8px;
}

.error_two_area {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  z-index: 1;
  height: 100vh;
  min-height: 750px;
}

.error_content_two h2 {
  font-size: 40px;
  font-weight: 500;
  line-height: 50px;
  color: #051441;
  margin-top: 40px;
}

.error_content_two p {
  font-size: 20px;
  line-height: 30px;
}

.error_content_two .search {
  margin: 45px 0 40px;
}

.error_content_two .search .form-control {
  background-color: #fff;
  -webkit-box-shadow: 0 4px 22px 0 rgba(12, 0, 46, 0.06);
  box-shadow: 0 4px 22px 0 rgba(12, 0, 46, 0.06);
  border: 1px solid transparent;
  border-radius: 45px;
  height: 60px;
  padding: 0 30px;
  color: #677294;
  font-size: 15px;
  font-weight: 300;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.error_content_two .search .form-control.placeholder {
  color: #677294;
}

.error_content_two .search .form-control:-moz-placeholder {
  color: #677294;
}

.error_content_two .search .form-control::-moz-placeholder {
  color: #677294;
}

.error_content_two .search .form-control::-webkit-input-placeholder {
  color: #677294;
}

.error_content_two .search .form-control:focus {
  border-color: #5e2ced;
}

.error_content_two .about_btn {
  background: #051441;
  color: #fff;
  border-radius: 3px;
  padding: 17px 35px;
  font-weight: 400;
}

.error_content_two .about_btn i {
  vertical-align: middle;
  padding-left: 5px;
}

.error_content_two .about_btn:hover {
  -webkit-box-shadow: none;
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #4b505e;
}

p {
  font-size: 16px;
  color: #677294;
}

p span {
  padding: 3px 8px;
}

.bg-dark {
  background: #222d39 !important;
}

.blockquote {
  border-left: 2px solid #4b505e;
  padding-left: 30px;
}

.blockquote p {
  font-size: 18px;
  color: #4b505e;
  font-style: italic;
  font-weight: 400;
  line-height: 30px;
}

.vc_column-inner h6 {
  font-weight: 400;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
}

input[type="text"] {
  -webkit-appearance: none;
  outline: none;
}

@-webkit-keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }

  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }

  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

@keyframes menu-animation {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.04) translateY(300%);
    transform: scale(0.04) translateY(300%);
  }

  40% {
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
    -webkit-transition: ease-out;
    -o-transition: ease-out;
    transition: ease-out;
    -webkit-transform: scale(0.04) translateY(0);
    transform: scale(0.04) translateY(0);
  }

  60% {
    opacity: 1;
    -webkit-transform: scale(0.02) translateY(0px);
    transform: scale(0.02) translateY(0px);
  }

  61% {
    -webkit-transform: scale(0.04);
    transform: scale(0.04);
  }

  99.9% {
    height: 0;
    padding-bottom: 100%;
    border-radius: 100%;
  }

  100% {
    -webkit-transform: scale(2);
    transform: scale(2);
    height: 100%;
    padding-bottom: 0;
    border-radius: 0;
  }
}

.height {
  height: 100%;
}

@-webkit-keyframes cloud {
  0% {
    left: 14%;
    top: 200px;
    opacity: 0;
  }

  20% {
    left: 44%;
    top: 100px;
    opacity: 1;
  }

  40% {
    left: 65%;
    top: 50px;
    opacity: 1;
  }

  60% {
    left: 80%;
    top: 130px;
    opacity: 1;
  }

  80% {
    left: 90%;
    top: 180px;
    opacity: 0.5;
  }

  100% {
    left: 100%;
    top: 230px;
    opacity: 0;
  }
}

@keyframes cloud {
  0% {
    left: 14%;
    top: 200px;
    opacity: 0;
  }

  20% {
    left: 44%;
    top: 100px;
    opacity: 1;
  }

  40% {
    left: 65%;
    top: 50px;
    opacity: 1;
  }

  60% {
    left: 80%;
    top: 130px;
    opacity: 1;
  }

  80% {
    left: 90%;
    top: 180px;
    opacity: 0.5;
  }

  100% {
    left: 100%;
    top: 230px;
    opacity: 0;
  }
}

@-webkit-keyframes animateCloud {
  0% {
    left: -20%;
    opacity: 1;
  }

  100% {
    left: 100%;
    opacity: 1;
  }
}

@keyframes animateCloud {
  0% {
    left: -20%;
    opacity: 1;
  }

  100% {
    left: 100%;
    opacity: 1;
  }
}

@-webkit-keyframes jurk {
  0% {
    -webkit-transform: translate3d(-10px, -10px, 0);
    transform: translate3d(-10px, -10px, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate3d(30px, 0px, 0);
    transform: translate3d(30px, 0px, 0);
    opacity: 1;
  }
}

@keyframes jurk {
  0% {
    -webkit-transform: translate3d(-10px, -10px, 0);
    transform: translate3d(-10px, -10px, 0);
    opacity: 1;
  }

  50% {
    -webkit-transform: translate3d(-20px, 20px, 0);
    transform: translate3d(-20px, 20px, 0);
    opacity: 1;
  }

  100% {
    -webkit-transform: translate3d(30px, 0px, 0);
    transform: translate3d(30px, 0px, 0);
    opacity: 1;
  }
}

@-webkit-keyframes pulsates {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.3);
    transform: translate(-50%, -50%) scale(0.3);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

@keyframes pulsates {
  0% {
    opacity: 1;
    -webkit-transform: translate(-50%, -50%) scale(0.3);
    transform: translate(-50%, -50%) scale(0.3);
  }

  100% {
    opacity: 0;
    -webkit-transform: translate(-50%, -50%) scale(2);
    transform: translate(-50%, -50%) scale(2);
  }
}

.banner_top .subcribes .btn_submit {
  background: #2c6deb;
}

.banner_top .subcribes .form-control:focus {
  border-color: #2c6deb;
}

.footer_bottom a,
.menu>.nav-item:hover .nav-link,
.menu>.nav-item.active .nav-link,
.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item .text:hover,
.menu>.nav-item.submenu .dropdown-menu .nav-item:hover>.nav-link,
.menu>.nav-item.submenu .dropdown-menu .nav-item:focus>.nav-link,
.menu>.nav-item.submenu .dropdown-menu .nav-item>.nav-link.active,
.menu>.nav-item.submenu.mega_menu.mega_menu_two .mega_menu_inner .dropdown-menu .nav-item .item.active .text {
  color: #2c6deb;
}

.active .round {
  -webkit-animation: zoomIn 0.7s both 0.4s;
  animation: zoomIn 0.7s both 0.4s;
}

.footer_bottom {
  font-size: 14px;
  font-weight: 300;
  line-height: 20px;
  color: #7f88a6;
  padding: 27px 0;
}

.footer_bottom p a {
  -webkit-transition: color 0.2s linear;
  -o-transition: color 0.2s linear;
  transition: color 0.2s linear;
}

.footer_bottom a:hover {
  color: #2c6deb;
}

.f_social_icon_two a {
  font-size: 18px;
  color: #969db4;
  margin: 0 8px;
  filter: grayscale(1);
}

.f_social_icon_two a img {
  width: 22px;
}

.new_footer_top .f_social_icon_two a:hover {
  filter: grayscale(0);
}

.new_footer_top {
  padding: 120px 0 270px;
  position: relative;
}

.new_footer_top .f-title {
  margin-bottom: 30px;
  color: #263b5e;
}

.new_footer_top .company_widget p {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: #c2d1e2;
  margin-bottom: 20px;
}

.new_footer_top .f_social_icon a {
  width: 44px;
  height: 44px;
  line-height: 43px;
  background: transparent;
  border: 1px solid #e2e2eb;
  font-size: 12px;
}

.new_footer_top .f_social_icon a:hover {
  background: #5e2ced;
  border-color: #5e2ced;
}

.new_footer_top .f_social_icon a+a {
  margin-left: 4px;
}

.f-logo img {
  width: 100%;
}

@-moz-keyframes myfirst {
  0% {
    left: -25%;
  }

  100% {
    left: 100%;
  }
}

@-webkit-keyframes myfirst {
  0% {
    left: -25%;
  }

  100% {
    left: 100%;
  }
}

@keyframes myfirst {
  0% {
    left: -25%;
  }

  100% {
    left: 100%;
  }
}

.sec_title p {
  max-width: 570px;
  margin-left: auto;
  margin-right: auto;
}

.event_gallery .portfolio_item .portfolio_img:hover {
  background-image: linear-gradient(-120deg, #fd475d 0%, #0e044b 100%);
  opacity: 0.8;
}

.ai_services img {
  width: 100%;
  height: 100%;
}

.ai_enterprice_img img {
  width: 100%;
  height: 100%;
}

.drop_r {
  top: calc(0vh - 90px) !important;
}

.main-title {
  font-size: 45px;
  position: relative;
  text-transform: capitalize;
}

.blog-article h2 {
  font-family: "Poppins", sans-serif;
  line-height: 36px !important;
  margin: 20px 0 25px !important;
  color: #1f1f1f;
  font-size: 25px;
}

.blog-article .cke_pastebin {
  font-size: 14px;
  line-height: 25px;
  font-family: "Poppins", sans-serif;
  color: #252b33 !important;
}

.blog-article strong {
  color: #1f1f1f;
}

.preloader_logo {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.preloader_logo object {
  width: 100px;
}

.client-cs-white-title {
  font-size: 60px;
  font-weight: 800;
  line-height: 70px;
  color: #252B33;
  letter-spacing: -1px;
  margin: 20px 0px 30px;
}

.client-cs-p-black p {
  margin-bottom: 15px;
}

.client-cs-p-black p:last-child {
  margin-bottom: 0px;
}

.client-cs-sub-title {
  font-size: 40px;
  line-height: 58px;
  color: #252B33;
  letter-spacing: -1.25px;
  font-weight: 800;
  margin-bottom: 20px;
}

.client-cs-h4 {
  font-size: 24px;
  color: #252B33;
  font-weight: 800;
  letter-spacing: -0.5px;
  line-height: 28px;
}

.container-fluid {
  padding: 0px;
}

.cs-mr-to-80 {
  margin-top: 80px;
}

.cs-requirment-sec .tagline {
  color: #F13C31;
  font-size: 24px;
  line-height: 38px;
  font-weight: 400;
}

.cs-project-sec .client-cs-p-black {
  width: 90%;
}

.key-benefits .benefits {
  padding-left: 0px;
  margin: 25px 0px 0px;
}

.key-benefits .benefits li {
  color: #252B33;
  font-size: 16px;
  line-height: 28px;
  display: inline-block;
  font-weight: 400;
  width: 95%;
  padding-left: 35px;
  list-style: none;
  margin-bottom: 30px;
  position: relative;
}

.key-benefits .benefits li span::before {
  content: " ";
  background: #F13C31;
  position: absolute;
  left: 0px;
  top: 9px;
  height: 8px;
  width: 16px;
}

.team-strategy {
  padding-top: 75px;
}

.team-strategy p {
  font-size: 25px;
  line-height: 35px;
  color: #252b33;
  font-weight: 400;
}

.cs-strategy-sec .client-cs-p-black {
  width: 96%;
}

.cs-strategy-sec .key-benefits {
  margin-top: 40px;
}

.services-sec .cs-services {
  margin-top: 40px;
}

.services-sec .cs-services h4 {
  margin-bottom: 0px;
}

.services-sec .cs-services .service {
  color: #F13C31;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
}

.cs-project-sec .client-cs-p-black {
  width: 90%;
}


.technology-sec .technology-tags .tags {
  display: inline-block;
  margin: 20px 10px 0px 0px;
}

.technology-sec .technology-tags .tags a {
  color: #252B33;
  display: block;
  padding: 2px 20px;
  font-size: 16px;
  font-weight: 400;
  background: #e4e4e4;
  border-radius: 4px;
  -webkit-transition: all 0.1s ease-in-out;
  -moz-transition: all 0.1s ease-in-out;
  -o-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}

.technology-sec .technology-tags .tags a:hover {
  background: #F13C31 !important;
  color: #fff !important;
}

.cs-result-sec {
  padding: 80px 0px;
  background: #F7F7F7;
}

.cs-result-sec .container {
  background: transparent;
}

.cs-result-sec .client-cs-p-black {
  width: 90%;
}

.cs-result-sec .technology-sec {
  margin-top: 60px;
}

.services-sec .cs-services {
  margin-top: 40px;
}

.services-sec {
  padding-top: 25px;
}

.brand-sec {
  padding-top: 25px;

}

.services-sec .cs-services h4 {
  margin-bottom: 0px;

}

.services-sec .cs-services .service {
  color: #F13C31;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
  text-decoration: none;
  display: block;
  margin-bottom: 8px;
}

.brand-sec .tech-icon {
  margin-top: 40px;
}

.brand-sec .tech-icon a .fa {
  color: #252B33;
  font-size: 30px;
  margin-right: 15px;
  border-radius: 4px;
  border: 1px solid #81959E;
  padding: 8px 11px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.brand-sec .tech-icon a:hover .fa {
  border: 1px solid #252B33;
  color: #252B33;
}

@media only screen and (min-width: 744px) and (max-width: 1199px) {
  .client-cs-banner {
    padding: 50px 0px 50px;
  }

  .client-cs-banner .case-title {
    max-width: 100%;
  }

  .client-cs-white-title {
    font-size: 46px !important;
    line-height: 56px;
  }

  .cs-result-sec {
    padding: 40px 0px;
  }

  .cs-result-sec .row {
    flex-direction: column-reverse;
  }

  .cs-result-sec .technology-sec {
    margin-top: 30px;
  }

  .brand-sec .tech-icon {
    margin-top: 0px;
  }
}

@media only screen and (max-width:1280px) {

  /*=== Brands Case Study Detail Page CSS Start ===*/
  .client-cs-white-title {
    font-size: 60px;
    line-height: 80px;
  }

  /*=== Brands Case Study Detail Page CSS End ===*/
}